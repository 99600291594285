export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135')];

export const server_loads = [];

export const dictionary = {
	"/": [35],
	"/forgot-password": [36],
	"/login": [37],
	"/login/first": [38],
	"/organizations": [126,[31]],
	"/organizations/add": [129,[31]],
	"/organizations/(setup)/users": [127,[31,32,33]],
	"/organizations/(setup)/users/[userId]": [128,[31,32,33]],
	"/org/[orgId]/(setup)/organization": [39,[2,3]],
	"/org/[orgId]/profile": [44,[2,5]],
	"/org/[orgId]/projects": [124,[2,30]],
	"/org/[orgId]/projects/add": [125,[2,30]],
	"/org/[orgId]/proj/[projId]/(setup)/blueprints": [45,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/blueprints/add": [54,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/blueprints/duplicate": [55,[6,7,9]],
	"/org/[orgId]/proj/[projId]/(setup)/blueprints/[sensorModelId]": [46,[6,7,8]],
	"/org/[orgId]/proj/[projId]/(setup)/blueprints/[sensorModelId]/charts": [47,[6,7,8]],
	"/org/[orgId]/proj/[projId]/(setup)/blueprints/[sensorModelId]/charts/timeseries/[chartId]": [48,[6,7,8]],
	"/org/[orgId]/proj/[projId]/(setup)/blueprints/[sensorModelId]/charts/wind-rose/[windRoseChartId]": [49,[6,7,8]],
	"/org/[orgId]/proj/[projId]/(setup)/blueprints/[sensorModelId]/charts/xy/[xyChartId]": [50,[6,7,8]],
	"/org/[orgId]/proj/[projId]/(setup)/blueprints/[sensorModelId]/dashboard": [51,[6,7,8]],
	"/org/[orgId]/proj/[projId]/(setup)/blueprints/[sensorModelId]/fields": [52,[6,7,8]],
	"/org/[orgId]/proj/[projId]/(setup)/blueprints/[sensorModelId]/formulas": [53,[6,7,8]],
	"/org/[orgId]/proj/[projId]/charts/custom": [110,[6,23,24]],
	"/org/[orgId]/proj/[projId]/charts/custom/[customChartId]": [111,[6,23,24]],
	"/org/[orgId]/proj/[projId]/charts/sensors": [112,[6,23]],
	"/org/[orgId]/proj/[projId]/(setup)/custom-charts": [56,[6,7,10]],
	"/org/[orgId]/proj/[projId]/(setup)/custom-charts/[customChartId]": [57,[6,7,10]],
	"/org/[orgId]/proj/[projId]/(setup)/custom-dashboards": [58,[6,7,11]],
	"/org/[orgId]/proj/[projId]/(setup)/custom-dashboards/[customDashboardId]": [59,[6,7,11]],
	"/org/[orgId]/proj/[projId]/dashboards/custom": [113,[6,25,26]],
	"/org/[orgId]/proj/[projId]/dashboards/custom/[customDashboardId]": [114,[6,25,26]],
	"/org/[orgId]/proj/[projId]/dashboards/sensors": [115,[6,25]],
	"/org/[orgId]/proj/[projId]/(setup)/data-connections": [60,[6,7,12]],
	"/org/[orgId]/proj/[projId]/(setup)/data-connections/ftps/add": [62,[6,7,12]],
	"/org/[orgId]/proj/[projId]/(setup)/data-connections/ftps/[ftpId]": [61,[6,7,12]],
	"/org/[orgId]/proj/[projId]/(setup)/data-connections/omnidots-honeycomb-api-tokens/add": [64,[6,7,12]],
	"/org/[orgId]/proj/[projId]/(setup)/data-connections/omnidots-honeycomb-api-tokens/[omnidotsHoneycombAPITokenId]": [63,[6,7,12]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources": [65,[6,7,13]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources/data-file-parsers/add": [71,[6,7,13]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources/data-file-parsers/[parserId]": [66,[6,7,13,14]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources/data-file-parsers/[parserId]/file-mapping": [67,[6,7,13,14]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources/data-file-parsers/[parserId]/logs": [68,[6,7,13,14]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources/data-file-parsers/[parserId]/logs/[logId]": [69,[6,7,13,14]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources/data-file-parsers/[parserId]/sensor-mapping": [70,[6,7,13,14]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources/omnidots/add": [74,[6,7,13]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources/omnidots/[omnidotsDataSourceId]": [72,[6,7,13,15]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources/omnidots/[omnidotsDataSourceId]/sensor-mapping": [73,[6,7,13,15]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources/senceive-consumers/add": [77,[6,7,13]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources/senceive-consumers/[senceiveId]": [75,[6,7,13,16]],
	"/org/[orgId]/proj/[projId]/(setup)/data-sources/senceive-consumers/[senceiveId]/sensor-mapping": [76,[6,7,13,16]],
	"/org/[orgId]/proj/[projId]/(setup)/manufacturers": [78,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/manufacturers/add": [80,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/manufacturers/[manufacturerId]": [79,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/map-images": [81,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/map-images/add": [84,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/map-images/[sectionViewId]": [82,[6,7,17]],
	"/org/[orgId]/proj/[projId]/(setup)/map-images/[sectionViewId]/coordinates": [83,[6,7,17]],
	"/org/[orgId]/proj/[projId]/map": [116,[6,27]],
	"/org/[orgId]/proj/[projId]/map/[sectionViewId]": [117,[6,27]],
	"/org/[orgId]/proj/[projId]/(setup)/project": [85,[6,7,18]],
	"/org/[orgId]/proj/[projId]/(setup)/project/alarm-levels": [86,[6,7,18]],
	"/org/[orgId]/proj/[projId]/readings": [118,[6,28]],
	"/org/[orgId]/proj/[projId]/readings/[sensorId]": [119,[6,28]],
	"/org/[orgId]/proj/[projId]/(setup)/reports": [87,[6,7,19]],
	"/org/[orgId]/proj/[projId]/(setup)/reports/add": [93,[6,7,19]],
	"/org/[orgId]/proj/[projId]/(setup)/reports/[reportId]": [88,[6,7,19,20]],
	"/org/[orgId]/proj/[projId]/(setup)/reports/[reportId]/generated-reports": [89,[6,7,19,20]],
	"/org/[orgId]/proj/[projId]/(setup)/reports/[reportId]/layout": [90,[6,7,19,20]],
	"/org/[orgId]/proj/[projId]/(setup)/reports/[reportId]/preview": [91],
	"/org/[orgId]/proj/[projId]/(setup)/reports/[reportId]/view/[reportFilename]": [92],
	"/org/[orgId]/proj/[projId]/(setup)/sensor-types": [94,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/sensor-types/add": [96,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/sensor-types/[sensorTypeId]": [95,[6,7]],
	"/org/[orgId]/proj/[projId]/sensors-overview": [120,[6]],
	"/org/[orgId]/proj/[projId]/sensors-overview/[sensorId]": [121,[6,29]],
	"/org/[orgId]/proj/[projId]/sensors-overview/[sensorId]/alarms": [122,[6,29]],
	"/org/[orgId]/proj/[projId]/sensors-overview/[sensorId]/calibrations": [123,[6,29]],
	"/org/[orgId]/proj/[projId]/(setup)/sensors": [97,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/sensors/add": [103,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/sensors/profile": [104,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/sensors/[sensorId]": [98,[6,7,21]],
	"/org/[orgId]/proj/[projId]/(setup)/sensors/[sensorId]/alarms": [99,[6,7,21]],
	"/org/[orgId]/proj/[projId]/(setup)/sensors/[sensorId]/calibrations": [100,[6,7,21]],
	"/org/[orgId]/proj/[projId]/(setup)/sensors/[sensorId]/mapping": [101,[6,7,21]],
	"/org/[orgId]/proj/[projId]/(setup)/sensors/[sensorId]/overdue-alarms": [102,[6,7,21]],
	"/org/[orgId]/proj/[projId]/(setup)/users": [105,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/users/add-to-project": [109,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/users/add": [108,[6,7]],
	"/org/[orgId]/proj/[projId]/(setup)/users/[userId]": [106,[6,7,22]],
	"/org/[orgId]/proj/[projId]/(setup)/users/[userId]/alarm-subscriptions": [107,[6,7,22]],
	"/org/[orgId]/(setup)/users": [40,[2,3,4]],
	"/org/[orgId]/(setup)/users/add-to-organization": [43,[2,3,4]],
	"/org/[orgId]/(setup)/users/add": [42,[2,3,4]],
	"/org/[orgId]/(setup)/users/[userId]": [41,[2,3,4]],
	"/playground": [130,[34]],
	"/playground/components": [131,[34]],
	"/playground/icons": [132,[34]],
	"/playground/parser-logs": [133,[34]],
	"/playground/sentry": [134,[34]],
	"/reset-password": [135]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};